<template>
  <div>
    <vx-card title="📝🔍Visualizador de cotizaciones" title-color="black">
      <p>Aqui puede visualizar todo el resumen de la cotización:</p>
      <vs-chip class="mt-4 mb-0" color="primary">{{ idUrl }}</vs-chip>
      <br />
      <br />
    </vx-card>
    <div>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left">
          <div class="p-1 mt-4">
            <vs-button
              @click.native="printInvoice()"
              icon-pack="feather"
              type="border"
              icon="icon-printer"
              >PDF</vs-button
            >
          </div>
          <div class="p-1 mt-4">
            <vs-button
              @click.native="popupActive = true"
              v-if="quoteData.apartaments[0].actual_state == 'Disponible'"
              icon-pack="feather"
              color="success"
              icon="icon-box"
              >Reservar</vs-button
            >
          </div>
          <div class="p-1 mt-4">
            <vs-button
              disabled="true"
              icon-pack="feather"
              color="danger"
              icon="icon-box"
              v-if="!(quoteData.apartaments[0].actual_state == 'Disponible')"
              >Reservada</vs-button
            >
          </div>
        </vs-col>

        <div></div>
      </vs-row>
    </div>

    <vs-popup
      :title="
        `Estas a punto de realizar una ${$tc(
          'message.reservation',
          1,
          user.countryCode
        )}`
      "
      :active.sync="popupActive"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <p class="alerta-reserva">
            ¿Deseas realizar la
            {{ $tc("message.reservation", 1, user.countryCode) }} del
            <b
              >{{ $tc("message.apartment", 1, user.countryCode) }}
              {{ quoteData.apartaments[0].number }}?</b
            >
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            ref="loadableButton"
            icon-pack="fas"
            color="success"
            icon="fa-flag"
            @click="loadigReservar()"
            >Reservar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <div class="mt-6">
      <vs-row>
        <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12">
          <vx-card class="mt-4">
            <vs-list-header
              color="grey"
              title="Detalles de cotización:"
            ></vs-list-header>
            <vs-list-item
              icon-pack="feather"
              icon="icon-calendar"
              title="Fecha de emisión"
              :subtitle="quoteData.quote_date_created"
            ></vs-list-item>
            <vs-list-item
              icon-pack="feather"
              icon="icon-calendar"
              title="Fecha de vencimiento"
              :subtitle="quoteData.quote_date_limit"
            ></vs-list-item>
          </vx-card>
          <vx-card class="mt-4">
            <vs-list>
              <vs-list-header
                color="grey"
                title="Vendedor asignado:"
              ></vs-list-header>

              <vs-list-item
                :title="
                  `${quoteData.seller[0].first_name} ${quoteData.seller[0].last_name}`
                "
                :subtitle="quoteData.seller[0].email"
              >
                <template slot="avatar">
                  <vs-avatar
                    :src="quoteData.seller[0].pic"
                    size="52px"
                    class="mr-3"
                  />
                </template>
              </vs-list-item>
            </vs-list>
            <vs-row></vs-row>
          </vx-card>
          <vx-card class="mt-4">
            <vs-list-header
              color="grey"
              title="Datos de cliente:"
            ></vs-list-header>
            <vs-list-item
              icon-pack="feather"
              icon="icon-user"
              title="Nombre"
              :subtitle="quoteData.client[0].first_name"
            ></vs-list-item>
            <vs-list-item
              icon-pack="feather"
              icon="icon-phone"
              title="Teléfono"
              :subtitle="quoteData.client[0].phone"
            ></vs-list-item>
            <vs-list-item
              icon-pack="feather"
              icon="icon-mail"
              title="Correo"
              :subtitle="quoteData.client[0].email"
            ></vs-list-item>
          </vx-card>
        </vs-col>
        <vs-col vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12">
          <div class="p-6">
            <h4>Resumen de cotización:</h4>
            <vs-divider></vs-divider>
            <vx-card>
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="7"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <div>
                    <img
                      class="w-full"
                      :src="quoteData.apartaments[0].plane_img"
                    />
                  </div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="5"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <vs-list>
                    <vs-list-header
                      :title="
                        `Unidad: ${quoteData.apartaments[0].number} - ${quoteData.proyect_name}`
                      "
                    ></vs-list-header>

                    <vs-list-item
                      icon="texture"
                      :title="
                        `Área: ${quoteData.apartaments[0].construction_area} m2`
                      "
                    ></vs-list-item>
                    <vs-list-item
                      icon="local_hotel"
                      :title="
                        `Habitaciones: ${quoteData.apartaments[0].bedrooms}`
                      "
                    ></vs-list-item>
                    <vs-list-item
                      icon="bathtub"
                      :title="`Baños: ${quoteData.apartaments[0].bathrooms}`"
                    ></vs-list-item>
                    <vs-list-item
                      icon="time_to_leave"
                      :title="
                        `Parqueaderos: ${quoteData.apartaments[0].parkings_assigned.length}`
                      "
                    ></vs-list-item>

                    <vs-list-item
                      icon="receipt"
                      title="Total"
                      :subtitle="
                        `Q. ${quoteData.total_mount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                      "
                    ></vs-list-item>

                    <vs-list-header
                      v-show="false"
                      title="Adicionales"
                      color="success"
                    ></vs-list-header>
                  </vs-list>
                </vs-col>
              </vs-row>
            </vx-card>
            <div class="mt-8">
              <h4>Detalles de pago:</h4>
              <vs-divider></vs-divider>
            </div>
            <vx-card class="mt-4">
              <vs-list>
                <vs-list-item
                  icon="filter_1"
                  :title="`${$tc('message.reservation', 1, user.countryCode)}:`"
                  :subtitle="
                    `Q. ${quoteData.reserve_mount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                  "
                ></vs-list-item>
                <vs-list-item
                  icon="description"
                  title="Firma promesa:"
                  :subtitle="
                    `Q. ${quoteData.promise_sign_mount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                  "
                ></vs-list-item>
                <vs-list-item
                  icon="filter_2"
                  title="Monto de enganche:"
                  :subtitle="
                    `Q. ${quoteData.fraction_total_mount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - ${
                      quoteData.fraction_month_selected
                    } Meses `
                  "
                ></vs-list-item>
                <vs-list-item
                  v-if="jsonQuotes.length == 0"
                  icon="check"
                  title="Cuota nivelada de enganche:"
                  :subtitle="
                    `Q. ${quoteData.fraction_fee_mount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                  "
                ></vs-list-item>
                <br v-if="!(jsonQuotes.length == 0)" />
                <vs-list v-if="!(jsonQuotes.length == 0)">
                  <vs-list-header
                    title="Cuotas personalizadas:"
                    color="grey"
                  ></vs-list-header>
                  <vs-list-item
                    icon-pack="feather"
                    v-for="v in jsonQuotes"
                    :key="v.index"
                    icon="icon-calendar"
                    :title="v.Mes"
                    :subtitle="`Q. ${v.Monto}`"
                  ></vs-list-item>
                  <br v-if="!(jsonQuotes.length == 0)" />
                </vs-list>
                <vs-list-item
                  icon="filter_3"
                  title="Monto de financiamiento:"
                  :subtitle="
                    `Q. ${quoteData.financing_total_mount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
                  "
                ></vs-list-item>
                <vs-list-item
                  icon="account_balance"
                  title="Entidad seleccionada:"
                  :subtitle="
                    ` ${quoteData.financing_name} - Tasa ${quoteData.financing_interest_rate}% - ${quoteData.financing_years_selected} Años`
                  "
                ></vs-list-item>
                <vs-list-item
                  icon="check"
                  title="Cuota nivelada:"
                  :subtitle="
                    `Q. ${quoteData.financing_fee_mount
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                  "
                ></vs-list-item>
              </vs-list>
            </vx-card>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapState } from "vuex";

export default {
  data() {
    return {
      quoteData: [],
      idUrl: "",
      popupActive: false
    };
  },
  mounted() {
    this.getQuoteID();
    this.getQuoteData();
    this.getSellerData();
    this.$store.dispatch("autoLogin");
  },
  computed: {
    ...mapState("auth", ["user"]),
    jsonQuotes() {
      let Quotes = this.quoteData.depositQuotes;
      return JSON.parse(Quotes);
    }
  },
  methods: {
    loadigReservar() {
      this.popupActive = false;
      this.$vs.loading({
        type: "sound"
      });
      setTimeout(() => {
        this.generarReserva();
      }, 4000);
    },
    printInvoice() {
      const base = new URL(process.env.VUE_APP_GRAPHQL_HTTP);
      const url = base.protocol + "//" + base.host + "/pdf/" + this.idUrl;
      window.open(url, "_blank");
    },
    getQuoteID() {
      this.idUrl = this.$route.params.Id;
    },
    getQuoteData() {
      this.$apollo
        .query({
          query: gql`
            query($quoteID: ID!) {
              getQuoteByID(quoteID: $quoteID) {
                _id
                developerCompanyName
                barCode
                developerCompanyName
                quote_date_created
                quote_date_limit
                discount_mount
                reserve_mount
                promise_sign_mount
                fraction_total_mount
                fraction_month_selected
                fraction_fee_mount
                property_value
                parkings_mount
                warehouses_mount
                taxes_mount
                total_mount
                financing_name
                financing_total_mount
                financing_interest_rate
                financing_years_selected
                financing_fee_mount
                proyect_name
                logo_quote_proyect
                quote_bank_calification_requirements
                general_apartament_description
                quote_terms
                esign
                depositQuotes
                seller {
                  _id
                  first_name
                  last_name
                  email
                  pic
                }
                client {
                  first_name
                  last_name
                  email
                  phone
                }
                apartaments {
                  _id
                  proyect_id
                  price
                  number
                  living_square_mts
                  bedrooms
                  bathrooms
                  plane_img
                  actual_state
                  balcony
                  actual_state
                  parkings_assigned {
                    _id
                    number
                    price
                  }
                }
                parkings {
                  price
                }
                warehouses {
                  price
                }
              }
            }
          `,
          variables: {
            quoteID: this.idUrl
          }
        })
        .then(data => {
          this.quoteData = data.data.getQuoteByID;
          this.sellerID = data.data.getQuoteByID.seller[0]._id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    generarReserva() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation(
              $userID: String!
              $developerID: String!
              $quotesID: String!
            ) {
              newReserve(
                userID: $userID
                developerID: $developerID
                quotesID: $quotesID
              ) {
                _id
                date_created
                quote {
                  proyect_name
                  apartaments {
                    number
                  }
                }
              }
            }
          `,
          variables: {
            userID: localStorage.user_id.toString(),
            developerID: localStorage.company_id.toString(),
            quotesID: this.idUrl.toString()
          }
        })
        .then(data => {
          this.addReservetoDeveloper(
            data.data.newReserve._id,
            data.data.newReserve.quote[0].apartaments[0].number
          );
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: `No ha sido posible hacer la ${this.$tc(
              "message.reservation",
              1,
              this.user.countryCode
            )}`,
            text: `${error.message}`,
            iconPack: "feather",
            icon: "icon-x-circle",
            color: "danger",
            time: 8000
          });
        });
    },
    addReservetoDeveloper(id, nApartamento) {
      const the = this;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($reserveID: ID!, $developerID: ID!) {
              addReserveToDeveloper(
                reserveID: $reserveID
                developerID: $developerID
              ) {
                _id
                name
              }
            }
          `,
          variables: {
            reserveID: this.quoteData.apartaments[0]._id,
            developerID: localStorage.company_id
          }
        })
        .then(function() {
          the.$vs.loading.close();
          //router.push({ path: "/reservas" });
          the.createNotification();
          the.$vs.notify({
            title: `👏👏 Felicidades!!! 🎉🎉`,
            text: `Se realizo con exito ${this.$tc(
              "message.reservation",
              1,
              this.user.countryCode
            )} del ${this.$tc(
              "message.apartment",
              1,
              this.user.countryCode
            )} ${nApartamento}`,
            iconPack: "feather",
            icon: "icon-x-circle",
            color: "success",
            time: 8000
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.avatarSeller {
  display: block;
  margin: 0 auto;
}
</style>
